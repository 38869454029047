html,
body,
#app,
#root,
#root > div,
#app > div {
  height: 100%;
  background-color: #f77200;
}

.card-shadow {
  -webkit-box-shadow: 3px 3px 25px -5px rgba(0, 0, 0, 0.65);
  -moz-box-shadow: 3px 3px 25px -5px rgba(0, 0, 0, 0.65);
  box-shadow: 3px 3px 25px -5px rgba(0, 0, 0, 0.65);
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

input[type="number"].no-arrows::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
